import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start()

import 'stylesheets/hartlepoolrestore/application.scss'

import 'javascripts/cms_pages'
import 'javascripts/next_events'
import 'javascripts/events'
import 'javascripts/navbar'
import 'javascripts/blog_article_browser'
import 'javascripts/site_searches'
import 'javascripts/documents'
import 'javascripts/category_browser'

document.addEventListener("DOMContentLoaded", function() {

  var alert_close = document.querySelector('#alert_close');

  if(alert_close) {
    alert_close.addEventListener('click', function (event) {
      document.querySelector('#alert_alert').remove()
    });
  }


  var notice_close = document.querySelector('#notice_close');

  if(notice_close) {
    notice_close.addEventListener('click', function (event) {
      document.querySelector('#alert_notice').remove()
    });
  }

});
